import { WFComponent, WFFormComponent, navigate } from "@xatom/core";
import { publicQL } from "../../graphql";
import { GetAllCoursesProgressDocument, GetNumberOfCoursesDocument, PublicUploadAvatarDocument, UserMeDocument } from "../../graphql/graphql";
import { PUBLIC_PATHS, S3_BASE_URL } from "../../config";
import { logoutPublicAuth } from "../../auth/public";
import { setWeglot } from "client-utils/utility-functions";
const defaultUsrAvatar = "https://assets-global.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg";
let loaderProgress = 0;

export const userSidebar = () => {
    try {
        setWeglot();
        const userDataReq = publicQL.query(UserMeDocument);
        const avatarChangeReq = publicQL.mutation(PublicUploadAvatarDocument);
        const logoutBtn = new WFComponent(`[xa-type="sidebar-signout-btn"]`);
        logoutBtn.on("click", () => {
            logoutPublicAuth();
        });
        const userImage = new WFComponent(`[xa-type="user-avatar"]`);
        const userImagePlaceholderWrap = new WFComponent(`[xa-type="sidebar-user-placeholder-wrap"]`);
        const userImagePlaceHolder = userImagePlaceholderWrap.getChildAsComponent(`[xa-type="sidebar-user-placeholder"]`);
        const userName = new WFComponent(`[xa-type="user-name"]`);
        const userEmail = new WFComponent(`[xa-type="user-email"]`);
        const avatarLoaderWrap = new WFComponent(`[xa-type="avatar-loader-wrap"]`);
        const avatarLoader = avatarLoaderWrap.getChildAsComponent(`[xa-type="avatar-loader"]`);
        const getAllCoursesProgressReq = publicQL.query(GetAllCoursesProgressDocument);
        const avatarWrapperProgressRing = new WFComponent(`[xa-type="avatar-wrapper"]`);
        const getNumberOfCoursesReq = publicQL.query(GetNumberOfCoursesDocument);
        // Courses section
        getAllCoursesProgressReq.onData((data) => {
            getNumberOfCoursesReq.onData((res) => {
                const totalCoursesCount = res.getNumberOfCourses;
                const completedLessonsCount = data.getAllCoursesProgress.filter(cp => cp.status === "COMPLETE").length;
                setCourseProgressRing(avatarWrapperProgressRing, totalCoursesCount, completedLessonsCount);
            })
            getNumberOfCoursesReq.fetch();
        });

        getAllCoursesProgressReq.fetch();

        window.onload = function () {
            const loaderIntervalId = setInterval(() => {
                loaderAnimation(avatarLoader);
            }, 10);
        }

        userDataReq.onLoadingChange((status) => {
            if (status) {
                avatarLoaderWrap.removeCssClass("hide");
            }
        });

        userDataReq.onData((data) => {
            if (!data.userMe.enabled) {
                logoutPublicAuth("disabled");
            } else {
                userName.getElement().innerHTML = `${data.userMe.firstName} ${data.userMe.lastName}`;
                userEmail.setText(data.userMe.email);
                userName.removeCssClass("disabled");
                userEmail.removeCssClass("disabled");
                if (data.userMe.avatar) {
                    userImage.setAttribute("src", `${S3_BASE_URL}${data.userMe.avatar}`);
                    userImage.setAttribute("srcset", `${S3_BASE_URL}${data.userMe.avatar}`);
                    userImage.removeCssClass("hide");
                } else {
                    const fullName = `${data.userMe.firstName} ${data.userMe.lastName}`;
                    const initials = getInitials(fullName);
                    const imageColors = ['#F7E5C2', '#F2E5D1', '#EFEFEE', '#F6F6F7', '#D5E2E8', '#FBE1D5'];
                    userImagePlaceHolder.setText(`${initials}`);
                    userImagePlaceholderWrap.getElement().style.backgroundColor = getRandomValueFromArray(imageColors);
                    userImagePlaceholderWrap.removeCssClass("hide");
                    userImage.addCssClass("hide");
                }
                avatarLoaderWrap.addCssClass("hide");
            }
        });

        userDataReq.fetch();

        const userAvatarInput = new WFComponent(`#sidebar-dp-upload`);
        const userAvatarForm = new WFFormComponent(`[xa-type="avatar-upload-form"]`);
        // //Update user Avatar
        userAvatarInput.on("change", () => {
            if (userAvatarForm.getFormData() && userAvatarForm.getFormData().img) {
                const image: any = userAvatarForm.getFormData().img;
                userImage.addCssClass("hide");
                avatarLoaderWrap.removeCssClass("hide");
                avatarChangeReq.fetch({
                    imageFile: image
                });
            }
        });
        userAvatarForm.onFormSubmit((data) => {
            //do nothing
        });

        avatarChangeReq.onError((err) => {
        })

        avatarChangeReq.onData((data) => {
            userAvatarForm.resetForm();
            userImage.setAttribute("src", `${data.publicUploadAvatar}`);
            userImage.setAttribute("srcset", `${data.publicUploadAvatar}`);
            userImage.removeCssClass("hide");
            avatarLoaderWrap.addCssClass("hide");
        })

        window.addEventListener('resize', () => {
            location.reload();
        });
    } catch (err) {
        console.log("We think this page doesn't have a sidebar. Check this error - ", err.message);
    }


    function getInitials(name) {
        const words = name.split(' ');
        const initials = words.map(word => word.charAt(0).toUpperCase()).join('');
        return initials;
    }


    function getRandomValueFromArray(array) {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    }

    function loaderAnimation(loader) {
        loader.getElement().style.background = `conic-gradient(var(--background-color--cta) ${loaderProgress * 3.6}deg, var(--background-color--white) 0deg)`;
        loaderProgress = (loaderProgress + 1) % 100;
    }

    const setCourseProgressRing = (courseCard: WFComponent<HTMLElement>, totalLessons: number, completedLessons: number) => {
        let progressStartValue = 0,
            speed = 10;
        const progressPercentage = (completedLessons * 100) / totalLessons;
        let progress = setInterval(() => {
            progressStartValue++;
            courseCard.getElement().style.background = `conic-gradient(var(--background-color--cta) ${progressStartValue * 3.6}deg, var(--background-color--dark) 0deg)`
            if (progressStartValue >= progressPercentage) {
                clearInterval(progress);
            }
        }, speed);
    }
}