import { WFComponent } from "@xatom/core";
import { moment } from "./datePicker";
import { GetAllCoursesWithLessonsDocument } from "../../apps/client/src/graphql/graphql";
import { publicQL } from "../../apps/client/src/graphql";
import { lpBGImages } from "./commonVariables";

export const formatDate = (inputDate, noWords = false) => {
    const currentDate = new Date();
    const targetDate = new Date(inputDate);

    if (!noWords) {
        // Check if it's today
        if (
            targetDate.getDate() === currentDate.getDate() &&
            targetDate.getMonth() === currentDate.getMonth() &&
            targetDate.getFullYear() === currentDate.getFullYear()
        ) {
            return 'Today';
        }

        // Check if it's yesterday
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);

        if (
            targetDate.getDate() === yesterday.getDate() &&
            targetDate.getMonth() === yesterday.getMonth() &&
            targetDate.getFullYear() === yesterday.getFullYear()
        ) {
            return 'Yesterday';
        }
    }

    // Format the date in the specified manner
    return targetDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
}


export const sortList = (list, type, order?) => {
    if (order && order === 'asc') {
        if (type === 'byLastLogin') {
            return list.sort((a, b) => {
                const dateA = new Date(b.lastLogin.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.lastLogin.split('/').reverse().join('/')) as any;
                return dateB - dateA;
            });
        } else if (type === 'byJoinedDate') {
            return list.sort((a, b) => {
                const dateA = new Date(b.joinDate.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.joinDate.split('/').reverse().join('/')) as any;
                return dateB - dateA;
            });
        }
    } else if (order && order === 'desc') {
        if (type === 'byLastLogin') {
            return list.sort((a, b) => {
                const dateA = new Date(b.lastLogin.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.lastLogin.split('/').reverse().join('/')) as any;
                return dateA - dateB;
            });
        } else if (type === 'byJoinedDate') {
            return list.sort((a, b) => {
                const dateA = new Date(b.joinDate.split('/').reverse().join('/')) as any;
                const dateB = new Date(a.joinDate.split('/').reverse().join('/')) as any;
                return dateA - dateB;
            });
        }
    } else if (order === 'enabledFirst' && type === 'byStatus') {
        return list.sort((a, b) => {
            return b.status - a.status;
        });
    } else if (order === 'disabledFirst' && type === 'byStatus') {
        return list.sort((a, b) => {
            return a.status - b.status;
        });
    } else if (order === 'completedFirst' && type === 'byProgress') {
        return list.sort((a, b) => {
            if (a["course-status"] === "COMPLETE" && b["course-status"] === "IN_PROGRESS") {
                return -1; // "a" should come before "b"
            } else if (a["course-status"] === "IN_PROGRESS" && b["course-status"] === "COMPLETE") {
                return 1; // "b" should come before "a"
            } else {
                return 0; // Leave order unchanged
            }
        });
    } else if (order === 'notCompletedFirst' && type === 'byProgress') {
        return list.sort((a, b) => {
            if (a["course-status"] === "IN_PROGRESS" && b["course-status"] === "COMPLETE") {
                return -1; // "a" should come before "b"
            } else if (a["course-status"] === "COMPLETE" && b["course-status"] === "IN_PROGRESS") {
                return 1; // "b" should come before "a"
            } else {
                return 0; // Leave order unchanged
            }
        });
    }
}

export const setCourseProgressRing = (courseCard: WFComponent<HTMLElement>, totalLessons: number, completedLessons: number) => {
    let circularProgress = courseCard.getChildAsComponent(".circular-progress");
    let progressStartValue = 0,
        speed = 10;
    const progressPercentage = (completedLessons * 100) / totalLessons;
    let progress = setInterval(() => {
        progressStartValue++;
        circularProgress.getElement().style.background = `conic-gradient(#00B8B4 ${progressStartValue * 3.6}deg, #ededed 0deg)`
        if (progressStartValue >= progressPercentage) {
            clearInterval(progress);
        }
    }, speed);
}

export const generateCourseAxes = (noOfCourses) => {
    let xValues = []
    for (let i = 1; i <= 6; i++) {
        if (i <= noOfCourses) {
            xValues.push(`C${i}`);
        } else {
            xValues.push("");
        }
    }
    return xValues;
}

export const defineDateRange = (rangeText: string) => {
    // console.log(rangeText)
    switch (rangeText) {
        case "Today":
            return [moment(), moment()];
            break;

        case "Yesterday":
            return [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
            break;

        case "Last 7 days":

            return [moment().subtract(6, 'days'), moment()];
            break;

        case "Last 30 days":
            return [moment().subtract(30, 'days'), moment()];
            break;

        case "This Month":
            return [moment().startOf('month'), moment()];
            break;

        case "Last 90 Days":
            return [moment().subtract(89, 'days'), moment()];
            break;

        case "Last 12 Months":
            return [moment().subtract(11, 'months').startOf('month'), moment()];
            break;

        case "Last 2 Years":
            return [moment().subtract(2, 'years').startOf('year'), moment()];
            break;

        default:
            return [5, 2];
            break;
    }
}

export const setOTPInput = () => {
    const codes: any = document.querySelectorAll('.form-input-verification_code');

    codes[0].focus();

    codes.forEach((code, idx) => {
        code.addEventListener("keyup", (e) => {
            if (e.key >= 0 && e.key <= 9) {
                codes[idx].value = e.key;
                requestAnimationFrame(() => {
                    if (codes[idx + 1]) {
                        codes[idx + 1].focus();
                        // codes[idx + 1].value = "";
                    }
                });
            } else if (e.key === "Backspace") {
                requestAnimationFrame(() => {
                    if (codes[idx - 1]) {
                        codes[idx].value = "";
                        codes[idx - 1].focus();
                    }
                })
            }
        });

        code.addEventListener("paste", (e) => {
            e.preventDefault();
            const inputValue = (e.clipboardData || window.Clipboard).getData('text');
            console.log(inputValue.length);
            if (/^\d{4}$/.test(inputValue) && inputValue.length === 4) {
                console.log("executed");
                // Auto-distribute digits to the respective input fields
                codes.forEach((digitInput, i) => {
                    digitInput.value = inputValue[i];
                });

                // Focus on the last input field
                codes[codes.length - 1].focus();
            }
        });
    });
}

export const setTriDoughnutChart = (chart: WFComponent<HTMLElement>, totalCount: number, value1: number, value2: number, value3: number) => {
    let degValue1: number, degValue2: number, degValue3: number;
    degValue1 = (354 / totalCount) * value1;
    degValue2 = (354 / totalCount) * value2;
    degValue3 = (354 / totalCount) * value3;

    if (degValue1) {

    }
    chart.getElement().style.background = `conic-gradient(
        var(--border-color--error) 0deg, 
        var(--border-color--error) ${degValue1}deg, 
        var(--background-color--card-background) ${degValue1}deg,
        var(--background-color--card-background) ${degValue1 + 2}deg,
        var(--border-color--warning) ${degValue1 + 2}deg, 
        var(--border-color--warning) ${degValue1 + degValue2 + 2}deg,
        var(--background-color--card-background) ${degValue1 + degValue2 + 2}deg, 
        var(--background-color--card-background) ${degValue1 + degValue2 + 4}deg,
        var(--border-color--success) ${degValue1 + degValue2 + 4}deg,
        var(--border-color--success) ${degValue1 + degValue2 + degValue3 + 4}deg,
        var(--background-color--card-background) ${degValue1 + degValue2 + degValue3 + 4}deg,
        var(--background-color--card-background) 360deg
    )`;
}

export const setWeglot = (isAbbriviated: boolean = false) => {
    if (!Weglot) {
        return;
    }

    const list = new WFComponent(`[xa-type="links"]`);
    list.setHTML("");
    const toggleFlag = new WFComponent(`[xa-type="toggle-flag"]`);
    const toggleText = new WFComponent(`[xa-type="toggle-text"]`);
    toggleText.setText("");
    let selectedLang = "en";
    const changeLanguage = (lang: string) => {
        Weglot.switchTo(lang);
    };

    Weglot.on("languageChanged", (newLang: string) => {
        list.setHTML("");
        selectedLang = newLang;
        setLanguages();
    })

    const setLanguages = () => {
        let availableLanguages = ["en", "ja", "es", "fr", "zh", "pt", "it", "de", "ru", "ro"];
        for (let i = 0; i < availableLanguages.length; i++) {
            let lang = availableLanguages[i];
            const a = document.createElement("a");
            a.className = "sidebar_dropdown-link w-inline-block";
            a.tabIndex = 0;
            a.href = "#";
            const img = document.createElement("img");
            img.loading = "lazy";
            img.className = "sidebar_language_flag-img";
            const div = document.createElement("div");
            div.className = "text-size-small";
            if (lang === "en") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/gb.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "English"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ja") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/jp.svg"
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "日本語"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "es") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/es.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Español"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "pt") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/pt.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Português"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "it") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/it.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Italiano"
                a.onclick = () => {
                    changeLanguage(lang);
                };

            } else if (lang === "de") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/de.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Deutsch"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "fr") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/fr.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Français"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "zh") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/cn.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "中文 (简体)"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ru") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ru.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Русский"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            } else if (lang === "ro") {
                const imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ro.svg";
                img.src = imgURL;
                img.srcset = imgURL;
                div.innerText = "Română"
                a.onclick = () => {
                    changeLanguage(lang);
                };
            }
            a.appendChild(img);
            a.appendChild(div);
            list.appendChild(a);
        }
        setSelectedLanguage();
    }


    const setSelectedLanguage = () => {
        let imgURL = "", languageText = "";
        if (selectedLang === "en") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/gb.svg";
            if (isAbbriviated) {
                languageText = "EN"
            } else {
                languageText = "English"
            }
        } else if (selectedLang === "ja") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/jp.svg"
            if (isAbbriviated) {
                languageText = "JA"
            } else {
                languageText = "日本語"
            }
        } else if (selectedLang === "es") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/es.svg";
            if (isAbbriviated) {
                languageText = "ES"
            } else {
                languageText = "Español"
            }
        } else if (selectedLang === "pt") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/pt.svg";
            if (isAbbriviated) {
                languageText = "PT"
            } else {
                languageText = "Português"
            }
        } else if (selectedLang === "it") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/it.svg";
            if (isAbbriviated) {
                languageText = "IT"
            } else {
                languageText = "Italiano"
            }
        } else if (selectedLang === "de") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/de.svg";
            if (isAbbriviated) {
                languageText = "DE"
            } else {
                languageText = "Deutsch"
            }
        } else if (selectedLang === "fr") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/fr.svg";
            if (isAbbriviated) {
                languageText = "FR"
            } else {
                languageText = "Français"
            }
        } else if (selectedLang === "zh") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/cn.svg";
            if (isAbbriviated) {
                languageText = "ZH"
            } else {
                languageText = "中文 (简体)"
            }
        } else if (selectedLang === "ru") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ru.svg";
            if (isAbbriviated) {
                languageText = "RU"
            } else {
                languageText = "Русский"
            }
        } else if (selectedLang === "ro") {
            imgURL = "https://cdn.weglot.com/flags/rectangle_mat/ro.svg";
            if (isAbbriviated) {
                languageText = "RO"
            } else {
                languageText = "Română"
            }
        }
        toggleText.setText(languageText);
        toggleFlag.setAttribute("src", imgURL);
        toggleFlag.setAttribute("srcSet", imgURL);
    }

    setLanguages();
}

export const getGreeting = () => {
    // Get the current time
    const now = new Date();
    const hours = now.getHours();

    // Determine the appropriate greeting
    let greeting;
    if (hours < 12) {
        greeting = "Good morning";
    } else if (hours < 18) {
        greeting = "Good afternoon";
    } else {
        greeting = "Good evening";
    }

    return greeting;
}

export const toCamelCase = (str) => {
    return str
        .toLowerCase()
        .split(/[\s_-]+/)
        .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
        .join('');
}

export const capitalizeFirstLetter = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export const handleResize = () => {
    const screenWidth = window.innerWidth;
    console.log(`Current screen width: ${screenWidth}px`);

    // Add your custom logic here
    if (screenWidth < 768) {
        console.log('Screen width is less than 768px (mobile view)');
        // Do something for mobile view
    } else if (screenWidth < 1024) {
        console.log('Screen width is less than 1024px (tablet view)');
        // Do something for tablet view
    } else {
        console.log('Screen width is 1024px or more (desktop view)');
        // Do something for desktop view
    }
}

export const setLearningPath = (courseProgressData) => {
    const learningPath = new WFComponent(`[xa-type="learning-path"]`);
    const signBoards = learningPath.getChildAsComponents(`[xa-type="badge"]`);
    const completedCourses = courseProgressData.getAllCoursesProgress.filter(cp => cp.status === "COMPLETE");
    const completedCourseNames = completedCourses.map(progress => progress.course.name);
    const getAllCoursesReq = publicQL.query(GetAllCoursesWithLessonsDocument);

    // To show appropriate number of signboards
    let availableSignBoards = [];

    // for lottie
    let skiStage =
        completedCourses.length <= 13
            ? completedCourses.length
            : completedCourses.length % 13;


    getAllCoursesReq.onData((data) => {
        const courses = data.getAllCoursesWithLessons;
        const sortedCourses = [...courses].sort((a, b) => {
            const isACompleted = completedCourseNames.includes(a.name);
            const isBCompleted = completedCourseNames.includes(b.name);

            if (isACompleted && !isBCompleted) return -1;
            if (!isACompleted && isBCompleted) return 1;
            return 0;
        });

        // Hide signboards more than course count
        if (signBoards.length > sortedCourses.length) {
            signBoards.forEach((sb, index) => {
                if (index > (sortedCourses.length - 1)) {
                    sb.addCssClass("hide");
                } else {
                    availableSignBoards.push(sb);
                }
            })
        } else {
            availableSignBoards = signBoards;
        }

        // Sort courses based on completed first.
        console.log(completedCourses)
        // Update course names on signboard tooltip
        if (sortedCourses && sortedCourses.length) {
            for (let i = 0; i < sortedCourses.length; i++) {
                if (signBoards[i]) {
                    const courseName = sortedCourses[i].name;
                    signBoards[i].updateTextViaAttrVar({
                        "course-name": courseName
                    });
                    signBoards[i].setAttribute("course-name", `${courseName}`);
                }
            }
        }

        // Update signboard image & ski lottie
        if (completedCourses.length && availableSignBoards.length) {
            completedCourses.forEach(course => {
                const sb = signBoards.find(sb => sb.getAttribute("course-name") === course.course.name);
                const signBoardImage = sb.getChildAsComponent(`[xa-type="sign-board-img"]`);
                const sbURL = course.course.courseSignboard;
                signBoardImage.setAttribute("src", sbURL);
                signBoardImage.setAttribute("srcset", sbURL);
            });

            setTimeout(() => {
                learningPath.getChildAsComponent(`[xa-type="lp-trigger-stage${skiStage}"]`).getElement().click();
            }, 500);
        }
    })

    getAllCoursesReq.fetch();
}

export const createLocationMarker = () => {
    const locationsMarkerSVGStr =
        `<svg width="32" height="32" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="37.8947" height="36" rx="18" fill="#F36D00"/>
        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M18.9468 33.75C28.1031 33.75 35.5257 26.6985 35.5257 18C35.5257 9.30152 28.1031 2.25 18.9468 2.25C9.79045 2.25 2.3678 9.30152 2.3678 18C2.3678 26.6985 9.79045 33.75 18.9468 33.75ZM18.9468 34.875C28.7571 34.875 36.7099 27.3198 36.7099 18C36.7099 8.68019 28.7571 1.125 18.9468 1.125C9.13643 1.125 1.18359 8.68019 1.18359 18C1.18359 27.3198 9.13643 34.875 18.9468 34.875Z" fill="white"/>
        <path d="M19 22C21.2091 22 23 20.2091 23 18C23 15.7909 21.2091 14 19 14C16.7909 14 15 15.7909 15 18C15 20.2091 16.7909 22 19 22Z" fill="#FEFEFE"/>
        <path d="M20 10.069V8H18V10.069C16.2403 10.2937 14.605 11.0962 13.3506 12.3506C12.0962 13.605 11.2937 15.2403 11.069 17H9V19H11.069C11.2934 20.7598 12.0957 22.3953 13.3502 23.6498C14.6047 24.9043 16.2402 25.7066 18 25.931V28H20V25.931C21.7599 25.7068 23.3955 24.9045 24.65 23.65C25.9045 22.3955 26.7068 20.7599 26.931 19H29V17H26.931C26.7066 15.2402 25.9043 13.6047 24.6498 12.3502C23.3953 11.0957 21.7598 10.2934 20 10.069ZM19 24C15.691 24 13 21.309 13 18C13 14.691 15.691 12 19 12C22.309 12 25 14.691 25 18C25 21.309 22.309 24 19 24Z" fill="#FEFEFE"/>
        </svg>
        `;
    const parser = new DOMParser();
    const doc = parser.parseFromString(locationsMarkerSVGStr, 'image/svg+xml');
    const svgElement = doc.documentElement;

    return svgElement;
}


export const cleanTextFromHTML = (input) => {
    // Parse the input string into a DOM object
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');

    // Extract text content from the DOM
    let text = doc.body.textContent || "";

    // Remove line breaks and multiple spaces
    text = text.replace(/[\r\n]+/g, ' ').replace(/\s\s+/g, ' ');

    // Optionally, decode HTML entities
    text = text.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
        .replace(/&([a-z]+);/gi, (match, entity) => {
            const entities = {
                quot: '"',
                amp: '&',
                lt: '<',
                gt: '>',
                nbsp: ' ',
                // Add other entities as needed
            };
            return entities[entity] || match;
        });

    return text.trim();
}

export const generatePaginationArray = (pageNo, totalPages) => {
    // Ensure currentPage is within the valid range
    pageNo = Math.max(1, Math.min(pageNo, totalPages));

    // If total pages are less than or equal to 7, return all pages
    if (totalPages <= 7) {
        let pageNoArr: any[] = Array.from({ length: totalPages }, (_, i) => i + 1);
        pageNoArr = pageNoArr.map((p) => {
            return { pageNo: p }
        });
        return pageNoArr;
    }

    // If there are more than 7 pages, calculate the range centered around pageNo
    let startPage = Math.max(1, pageNo - 2); // Start 2 pages before, but not below 1
    let endPage = Math.min(totalPages, pageNo + 2); // End 2 pages after, but not above total pages

    // Ensure the total number of pages in the range is 7
    let pageNoArr: any[] = [];

    if (startPage > 1) {
        pageNoArr.push(1); // Always show the first page
    }

    // Add pages between the start and end page
    for (let i = startPage; i <= endPage; i++) {
        pageNoArr.push(i);
    }

    if (endPage < totalPages) {
        pageNoArr.push(totalPages); // Always show the last page
    }

    // Adjust the pagination array length to always be 7 elements
    if (pageNoArr.length < 7) {
        const missingPages = 7 - pageNoArr.length;

        // Add missing pages before or after depending on where we need space
        if (pageNoArr[0] > 1) {
            const extraPages = Array.from({ length: missingPages }, (_, i) => totalPages - missingPages + i + 1);
            pageNoArr.unshift(...extraPages);
        } else if (pageNoArr[pageNoArr.length - 1] < totalPages) {
            const extraPages = Array.from({ length: missingPages }, (_, i) => i + pageNoArr[pageNoArr.length - 1] + 1);
            pageNoArr.push(...extraPages);
        }
    }
    pageNoArr = pageNoArr.map((p) => {
        return { pageNo: p }
    });
    return pageNoArr;
}