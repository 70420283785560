export const GQL_ENDPOINT = window.location.hostname.includes("vailresorts.com") ? "https://epic-pass-lms-api.devlab.zone/api/graphql" : window.location.hostname.includes("webflow.io") ? "https://epic-pass-lms-dev-api.devlab.zone/api/graphql" : window.location.hostname.includes("devlab.zone") ? "https://epic-pass-lms-staging-api.devlab.zone/api/graphql" : "http://localhost:4050/api/graphql"

export const S3_BASE_URL = window.location.hostname.includes("vailresorts.com") ? "https://epic-pass-lms-prod.s3.amazonaws.com/" : window.location.hostname.includes("devlab.zone") ? "https://epic-pass-lms-dev.s3.amazonaws.com/" : "https://epic-pass-lms-dev.s3.amazonaws.com/";

export const ADMIN_PATHS = {
    signIn: "/admin/sign-in",
    adminVerification: "/admin/verification",
    forgotPassword: "/admin/forgot-password",
    resetPassword: "/admin/reset-password",
    dashboard: "/admin/overview",
    settings: "/admin/settings",
    userReports: "/admin/user/user-activity",
    userListing: "/admin/user/list",
    userDetails: "/admin/user/view",
    courseReports: "/admin/course/report",
    courseListing: "/admin/course/list",
    courseDetail: "/admin/course/view",
    googleAnalytics: "/admin/analytics/ga",
};

export const PUBLIC_PATHS = {
    landingPage: "",
    signIn: "/user/sign-in",
    signUp: "/user/sign-up",
    userVerification: "/user/verification",
    forgotPassword: "/user/forgot-password",
    resetPassword: "/user/reset-password",
    userDisabled: "/user/disabled",
    dashboard: "/user/dashboard",
    settings: "/user/settings",
    courseList: "/user/courses",
    courseDetail: "/user/courses/(.*)",
    lessonDetail: "/lessons/(.*)",
    lessonDetailRoute: "/lessons",
    courseHighlightRoute: "/course-highlights",
    courseHighlight: "/course-highlights/(.*)",
    resortsList: "/user/resorts",
    resortsDetail: "/user/resorts/(.*)",
    famsList: "/user/virtual-fams",
    famsDetail: "/user/virtual-fams/(.*)",
    famsVideoDetail: "/user/fam-videos/(.*)",
    newsList: "/user/news",
    newsDetail: "/news/(.*)",
    newsDetailRoute: "/news",
    courseDetailRoute: "/user/courses",
    resources: "/user/resources",
    support: "/user/support",
    aiAssistant: "/user/ai-assistant",
    resortsListV2: "/user/resorts-v2",
    epicPasses: "/user/passes"
};

const WORDS_PER_MINUTE = 200;